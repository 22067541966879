$(document).ready(function() {
    initPopovers();
    initScrollbars();
    makeSpace();
    initFooter();

    if (window.location.hash.length > 0) {
        var id = window.location.hash.substr(3);
    }
});

window.onresize = function() {
    makeSpace();
};

function makeSpace() {
    if ($('.cookie-notification-container').length < 1) {
        return;
    }
    var $outerHeight = $('.cookie-notification').outerHeight();
    if ($('body').outerWidth() < 992) {
        $('html').css('padding-top', $outerHeight + 'px');
    } else {
        $('.cookie-notification-container').css('height', $outerHeight + 'px');
        $('.cookie-notification-container').css('display', 'block');
        $('html').css('padding-top', '0px');
    }
}

function initFooter() {
    $(document).on(
        'click',
        '.icon.winkler-expand, .icon.winkler-collapse',
        function() {
            $(this)
                .closest('.footer-group')
                .find('ul')
                .toggleClass('iwc-show-footer-subgroup');
            if ($(this).hasClass('winkler-expand')) {
                $(this).removeClass('winkler-expand');
                $(this).addClass('winkler-collapse');
            } else {
                $(this).removeClass('winkler-collapse');
                $(this).addClass('winkler-expand');
            }
        }
    );
}

function initScrollbars() {
    $('.horizontal-scrollbar').mCustomScrollbar({
        axis: 'x',
        setHeight: false,
        setWidth: false,
        scrollbarPosition: 'inside',
        scrollInertia: 2000,
        theme: 'dark-3',
        mouseWheel: {
            enable: false
        },
        scrollButtons: {
            enable: true
        },
        advanced: {
            updateOnContentResize: true,
            autoExpandHorizontalScroll: true
        }
    });
}

function initPopovers() {
    init_tooltip_popover($('.iwc-popover-tooltip'));
}

$(document).on('submit', '#form_categories', function() {
    var datastring = $(this).serialize();
    var form = $(this);
    if (
        typeof setFormParamToUrl == 'function' &&
        $(this).attr('data-param-to-hash') == 'true'
    ) {
        setFormParamToUrl($(this).attr('id'), datastring);
    }
    var raplaceElem = $(form).closest('.container');
    var parent = raplaceElem.parent();
    toggleLoading(parent);
    //! kamēr izstrāde --------
    setTimeout(function () {
        $.get(
            form.attr('action'),
            function(data) {
                $(document).trigger('iwc-event-trigger-search');
                raplaceElem.replaceWith(data);
                toggleLoading(parent);
            },
            'html'
        );
    }, 1000)
    //! ---------------------------------

    // $.ajax({
    //     type: 'POST',
    //     url: form.attr('action'),
    //     data: datastring,
    //     global: false,
    //     success: function(data) {
    //         $(document).trigger('iwc-event-trigger-search');
    //         raplaceElem.replaceWith(data);
    //     },
    //     error: function() {}
    // });
    return false;
});

function onHashChange() {
    var $dataString = getHashParamFromUrl();
    if ($dataString != '') {
        $.ajax({
            url: '',
            data: $dataString
        }).done(function(data) {
            $(document).trigger('iwc-event-trigger-search');
        });
    }
}

$(function() {
    $(window).on('hashchange', onHashChange);
});

$(document).on('iwc-event-trigger-search', function() {
    $('#form_material_filter').trigger('submit');
});

$(document).on('change', '#form_material_filter input', function() {
    $(this).closest('form').submit();
});


$(document).on('click', '.removeHashParam', function () {
    $(this).closest('form').submit();
})

$(document).on('submit', '#form_material_filter', function() {
    var form = $(this);
    var raplaceElem = $('.container.search-result');
    var parent = raplaceElem.parent();
    toggleLoading(parent);
    //! kamēr izstrāde --------
    setTimeout(function(){
        $.get(
            form.attr('action'),
            function(data) {
                raplaceElem.replaceWith(data);
                toggleLoading(parent);
            },
            'html'
        );
    }, 1000);
    //! ---------------------------------

    // $.ajax({
    //     type: 'POST',
    //     url: form.attr('action'),
    //     data: datastring,
    //     global: false,
    //     success: function(data) {
    //         raplaceElem.replaceWith(data);
    //     },
    //     error: function() {}
    // });
    return false;
});

function toggleLoading(element){
    element.toggleClass('loading');
}

$(document).on('click','.checkout-section.processed h2 a', function () {
    var wrap = $(this).closest('.checkout-section');

    changeStep(wrap);
    return false;
});

function nextStep(step) {
    var nextStep = step.next('.checkout-section');

    if(nextStep.length < 1){
        return false;
    }

    changeStep(nextStep)
}

function changeStep(step) {

    step.addClass('active').removeClass('processed').children('form').slideDown(300);
    step.siblings('.checkout-section').removeClass('active').children('form').slideUp(300);
    step.prevAll('.checkout-section').addClass('processed');
    step.nextAll('.checkout-section').removeClass('processed');

    setTimeout(function () {
        $('html, body').animate({
            scrollTop: step.offset().top
        }, 500);

    }, 300);
}

$(document).on('click', '.iwc-ui-co-prev-btn', function () {
    var wrap = $(this).closest('.checkout-section');
    var previousStep = wrap.prev('.checkout-section');

    if(previousStep.length < 1){
        return false;
    }
    changeStep(previousStep);

    return false;
});

$(document).ready(function () {
    changeStep($('.checkout-section.active'));
});